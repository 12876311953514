import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { FaMinus, FaPlus } from "react-icons/fa";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";

import FeaturePost from "../components/FeaturePost";
import Footer from "../components/Footer";
import Framed from "../components/Framed";
import Header from "../components/Header";
import Link from "../components/Link";
import { useHeaderImage } from "../components/images/homePageHeader";

const FishTopic = () => (
  <Topic
    title="Fish"
    intro="We are very confident the entire Sealord range is slave
          free, including their budget brand: Captain's Choice. We have been very impressed by their commitment to
          human rights. Brunswick sardines and Kingfisher prawns are also slave free, as is all New Zealand farmed salmon and all New Zealand
          shellfish."
    logos={[
      <StaticImage
        objectFit="contain"
        src="../images/logos/sealord_paua_logo.jpg"
        alt="logo for Sealord"
        height={85}
      />,
      <StaticImage
        objectFit="contain"
        src="../images/logos/brunswick_polish.png"
        alt="logo for Brunswick sardines"
        height={85}
      />,
      <StaticImage
        objectFit="contain"
        src="../images/logos/kingfisher.jpg"
        alt="logo for kingfisher prawns"
        height={85}
      />,
    ]}
  >
    <h5>Watch Out For:</h5>
    <p>
      Forced and child labour are common in the fishing industry: on boats, in
      fish farms and in fish processing facilities. These practises are most
      commonly associated with Thailand but occur throughout East and South-East
      Asia; slavery has also been reported on fishing boats in New Zealand
      waters. To see what forced labour in the fishing industry can look like,{" "}
      <Link href="https://www.youtube.com/watch?v=KkoWQFeS5TA&list=PLTzqLU5q3R31OQXFL2kHPBlDrjGgxImjn&index=1">
        watch Aung Ye Tung tell his story of being enslaved on a Thai squid boat
      </Link>
      .
    </p>
    <h5>How To Know:</h5>
    <p>
      There are no certifications to tell you these things haven’t occurred: you
      have to ask companies what happens throughout their supply chain instead.
      For farmed fish and prawns this includes asking how feed species were
      caught.
    </p>
    <h5>Good Options:</h5>
    <p>
      Your pets might be eating more fish than you! Fish is common in pet food
      even if it’s not in the product name - check the ingredients. Download{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/open?id=1hhvO7naM9vIQriOua28toxlMNzsMEgll"
      >
        summary pdf
      </Link>{" "}
      of good brands; read{" "}
      <Link href="/research/fish-petfood/">detailed research notes</Link>.
    </p>
    <p>
      Download an{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/file/d/1udUolH107raznWKbNo1yjirbXNKstnc8"
      >
        overview pdf
      </Link>{" "}
      of good brands of fish for human consumption.
    </p>
    <p>
      Tinned tuna, sardines and mackerel: download{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/open?id=1061T4EK_TdQ0jShw42_4HmfQdVr6dHe1"
      >
        summary pdf
      </Link>
      ; checkout out our{" "}
      <Link href="/guides/tinned-tuna-and-sardines/">buying guide</Link> or{" "}
      <Link href="/research/fish-tinned/">detailed research notes</Link>.
    </p>
    <p>
      Salmon (all forms - tinned and fresh): download{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/file/d/1k9kEVExqk4S-zg9erwscaqgk4mSeyKvl"
      >
        summary pdf
      </Link>
      ; checkout out our <Link href="/guides/salmon/">buying guide</Link> or{" "}
      <Link href="/research/fish-salmon/">detailed research notes</Link>.
    </p>
    <p>
      Shellfish and crustaceans (shrimp/prawns etc): download{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/file/d/1-6603Ot7MbggyDS4rSOBiA0cXfbux55l"
      >
        summary pdf
      </Link>
      ; checkout out our{" "}
      <Link href="/guides/shellfish-crustaceans/">buying guide</Link> or{" "}
      <Link href="/research/seafood/">detailed research notes</Link>.
    </p>
    <p>
      Fish oil and omega 3 supplements: download{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/open?id=1aOyCK-Q7uACJUirGe3B_NSfbxiEyirJw"
      >
        summary pdf
      </Link>
      ; read{" "}
      <Link href="/research/fish-supplements/">detailed research notes</Link>.
    </p>
    <p>
      From partly-published research (see tuna and salmon research above) we are
      confident the entire Sealord range is slave free. Sealord produce plain
      and flavoured tuna and salmon in both tins and pockets; they also produce
      mussels, tuna-based ready-meals and frozen fish products.
    </p>
    <p>We continue to research further sectors of the NZ fish market.</p>
  </Topic>
);

const CocoaTopic = () => (
  <Topic
    title="Cocoa"
    intro="Slave free cocoa products can easily be identified by the following logos.  
         Single origin Samoan cocoa is also slave free."
    logos={[
      <StaticImage
        objectFit="contain"
        src="../images/logos/rainforest-seal.png"
        alt="logo for the Rainforest Alliance"
        height={85}
      />,
      <StaticImage
        objectFit="contain"
        src="../images/logos/WFTO3543x3535.jpg"
        alt="logo for the WFTO"
        height={85}
      />,
      <StaticImage
        objectFit="contain"
        src="../images/logos/FAIRTRADE_Mark.png"
        alt="logo for FairTrade cocoa"
        height={85}
      />,
    ]}
  >
    <h5>Watch Out For:</h5>
    <p>
      Child and forced labour are both common on cocoa farms, especially in the
      Ivory Coast and Ghana. To learn more,{" "}
      <Link href="https://www.youtube.com/watch?v=BMIPhyw9uFw&list=PLTzqLU5q3R31OQXFL2kHPBlDrjGgxImjn&index=2&t=95">
        watch Kojo Mensah talk about the work his children used to do
      </Link>{" "}
      on his farm in Ghana.
    </p>
    <h5>How To Know:</h5>
    <p>Always look for certifications to be sure your cocoa is slave free.</p>
    <p>
      Not all certifications are reliable. We trust{" "}
      <Link href="/blog/2020-07-11-the-new-rainforest-alliance/">
        Rainforest Alliance
      </Link>
      ,{" "}
      <Link target="_blank" href="http://fairtrade.org.nz">
        Fairtrade
      </Link>{" "}
      and{" "}
      <Link target="_blank" href="https://wfto.com">
        WFTO
      </Link>
      . We have also found no evidence of slavery or child labour ever being
      practised on Samoan cocoa farms: we{" "}
      <Link href="/research/koko-samoa/">trust Samoan grown cocoa</Link> to be
      slave free.
    </p>
    <p>
      If you want to buy an uncertified product or one with a different
      certification,{" "}
      <Link href="/blog/2017-02-04-how-to-buy-chocolate-without-supporting-abuse-of-cocoa-growers/">
        read this first
      </Link>{" "}
      so you know how to check it’s slave free.
    </p>
    <h5>Good Options:</h5>
    <p>
      There are a wide range of products available with our trusted
      certifications: as well as chocolate you'll find ice cream, baking
      ingredients and even toiletries. Download either{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/file/d/1qfo4OnUe__V7tVFDmjXOZYevC2GOZyCR"
      >
        a one page guide
      </Link>{" "}
      <Link
        target="_blank"
        href="https://drive.google.com/open?id=1prhYppKbBv4dpso_5B-4reXZ9wp-7R1B"
      >
        or the full list
      </Link>
      ; or <Link href="/guides/cocoa/">read online</Link>.
    </p>
  </Topic>
);

const SugarTopic = () => (
  <Topic
    title="Sugar"
    intro="Slave free sugar can be identified by the following logos.  
         Australian-grown sugar is also slave free."
    logos={[
      <StaticImage
        objectFit="contain"
        src="../images/logos/WFTO3543x3535.jpg"
        alt="logo for the WFTO"
        height={85}
      />,
      <StaticImage
        objectFit="contain"
        src="../images/logos/FAIRTRADE_Mark.png"
        alt="logo for FairTrade"
        height={85}
      />,
      <StaticImage
        objectFit="contain"
        src="../images/logos/bonsucro-376x174.jpeg"
        alt="logo for Bonsucro sugar"
        height={85}
      />,
    ]}
  >
    <h5>Watch Out For:</h5>
    <p>
      Forced labour occurs on sugar farms in many countries; it is especially
      common in Brazil. This is fuelled in part by some wealthy sugar-growing
      countries (e.g. USA) subsidising their local industries, which pushes
      prices down internationally. To avoid supporting slavery, both subsidised
      and slave-grown sugar should be avoided.
    </p>
    <h5>How To Know:</h5>
    <p>
      We trust Bonsucro, WFTO and Fairtrade certifications, as well as
      Australian grown sugar. Read more about why{" "}
      <Link href="/guides/sugar/">here.</Link>
    </p>
    <h5>Good Options:</h5>
    <p>
      Countdown own brand sugar has Bonsucro certification: they do white,
      brown, caster and icing sugar.
    </p>
    <p>
      Trade Aid sugar has WFTO certification: they do muscovado, golden
      granulated and organic cane sugar.
    </p>
    <p>
      Sugary products with Fairtrade certification include: Karma Cola soft
      drinks, The Goodness dessert and coffee syrups, Nice Blocks ice blocks and
      Ben and Jerry's ice cream.
    </p>
    <p>
      Sugary products made with Australian-grown sugar include: Bundaberg ginger
      beer, Arnotts biscuits, the Coca-Cola drinks range and Countdown own brand
      jelly crystals and sweetened condensed milk.
    </p>
    <p>
      Download a{" "}
      <Link href="https://drive.google.com/file/d/1mFXERAyrhnX5NJwTc6GatHxJnHtXeZ0x/view?usp=sharing">
        summary pdf
      </Link>
      ; or <Link href="/guides/sugar/">read online</Link>. .
    </p>
  </Topic>
);

const LogoWrapper = (logo) => (
  <Center h="85px" w={{ base: "150px", lg: "185px" }}>
    {logo}
  </Center>
);

const Topic = ({ title, intro, logos, children }) => (
  <AccordionItem pt="40px" pb={["40px", null, "60px"]}>
    {({ isExpanded }) => (
      <Grid
        templateColumns={["1fr 1fr", null, "132px fit-content(75ch) 132px"]}
        templateAreas={[
          `"title toggle"
           "intro intro"
           "logos logos"
           "detail detail"`,
          null,
          `"title intro toggle"
           "logos logos logos"
           ". detail ."`,
        ]}
        gapx={0}
      >
        <GridItem area={"title"}>
          <Heading as="h3" fontSize="3xl">
            {title}
          </Heading>
        </GridItem>
        <GridItem area={"intro"}>
          <Text>{intro}</Text>
        </GridItem>
        <GridItem area={"toggle"}>
          <h3>
            <AccordionButton color="brand.lightBlue" justifyContent="end">
              {isExpanded ? <FaMinus size={30} /> : <FaPlus size={30} />}
            </AccordionButton>
          </h3>
        </GridItem>
        <GridItem
          area={"logos"}
          pt={["1rem", null, "40px"]}
          pb={["1rem", null, "20px"]}
        >
          <Flex
            wrap="wrap"
            gap={{ base: "1.5rem", md: "2rem" }}
            justifyContent={{ base: "center" }}
          >
            {logos.map(LogoWrapper)}
          </Flex>
        </GridItem>
        <GridItem area={"detail"}>
          <AccordionPanel p={0}>
            <Box textStyle="simple">{children}</Box>
          </AccordionPanel>
        </GridItem>
      </Grid>
    )}
  </AccordionItem>
);

const HomePage = () => (
  <>
    <Header imageHook={useHeaderImage}>
      <Heading as="p" fontSize="md">
        <Link
          target="_blank"
          href="https://www.globalslaveryindex.org/2018/findings/highlights/"
        >
          GLOBAL SLAVERY INDEX 2018
        </Link>
      </Heading>
      <Heading as="p" py={3}>
        “The dominant things we buy that are likely to have been produced by
        slave labour are computers and mobile phones, clothing, fish, cocoa and
        sugar.”
      </Heading>
      <Text fontSize="2xl" lineHeight={1.4} mt="1rem">
        People far away are enslaved to produce our food. We can stop that by
        only buying from companies that have rooted slavery out of their supply
        chains.
      </Text>
    </Header>

    <Box as="main" id="main" w="100%" bg="brand.light" color="black">
      <Framed>
        <Box h="90px" />
        <FeaturePost mb="70px" />
        <Box>
          <Heading as="h2" color="brand.lightBlue">
            We Recommend
          </Heading>

          <Center>
            <Accordion
              allowToggle
              allowMultiple
              // mx={[0, null, null, null, "160px"]}
              pt={[0, null, "2rem"]}
            >
              <Text pt="20px" pb="40px">
                Download a{" "}
                <Link
                  target="_blank"
                  href="https://drive.google.com/open?id=1_BzwqIx5FSpZjyUozsmc9_VxqQMsuDNI"
                >
                  summary pdf
                </Link>
                .
              </Text>
              <Divider
                borderColor="brand.lightBlue"
                borderBottomWidth="2px"
                opacity="1"
              />

              <FishTopic />
              <Divider
                borderColor="brand.lightBlue"
                borderBottomWidth="2px"
                opacity="1"
              />
              <CocoaTopic />

              <Divider
                borderColor="brand.lightBlue"
                borderBottomWidth="2px"
                opacity="1"
              />

              <SugarTopic />
            </Accordion>
          </Center>
        </Box>
      </Framed>
    </Box>

    <Footer />
  </>
);

export default HomePage;
