import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

export function useHeaderImage() {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "headers/sigmund-4ewSZirtA7U-unsplash.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  return {
    image,
    credit: "Sigmund on Unsplash",
    url: "https://unsplash.com/photos/4ewSZirtA7U",
  };
}
